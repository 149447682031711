
import Vue from "vue";
import KCrudTable, {
  CrudTableHeader,
} from "@/modules/crudTable/components/KCrudTable.vue";
import { PaginatedRequest } from "@/application/api/getPaginated";
import { mapGetters } from "vuex";
import RequiredClientDialog from "@/modules/client/components/RequiredClientDialog.vue";
import BulkDeleteButton from "@/modules/crudTable/components/BulkDeleteButton.vue";
import {
  addressBookIndex,
  AddressBookIndexItem,
} from "@/modules/addressbook/api/addressBookIndex";
import { addressBookAddressDestroy } from "@/modules/addressbook/api/addressBookAddressDestroy";

interface ComponentData {
  headers: CrudTableHeader[];
  selected: Partial<AddressBookIndexItem> & { clientId: number; id: number }[];
}

export default Vue.extend({
  name: "AddressBookTable",
  components: {
    BulkDeleteButton,
    RequiredClientDialog,
    KCrudTable,
  },
  data: (): ComponentData => ({
    headers: [
      {
        value: "deliveryAddress.companyName",
        typeParameters: { tooltipProperty: "errors" },
      },
      {
        value: "deliveryAddress.firstName",
        typeParameters: { tooltipProperty: "errors" },
      },
      {
        value: "deliveryAddress.lastName",
        typeParameters: { tooltipProperty: "errors" },
      },
      {
        value: "deliveryAddress.email",
        typeParameters: { tooltipProperty: "errors" },
      },
      {
        value: "deliveryAddress.city",
        typeParameters: { tooltipProperty: "errors" },
      },
      {
        value: "deliveryAddress.street",
        typeParameters: { tooltipProperty: "errors" },
      },
      {
        value: "deliveryAddress.postalCode",
        typeParameters: { tooltipProperty: "errors" },
      },
      {
        value: "deliveryAddress.houseNumber",
        typeParameters: { tooltipProperty: "errors" },
      },
      {
        value: "deliveryAddress.houseNumberAddition",
        typeParameters: { tooltipProperty: "errors" },
      },
    ],
    selected: [],
  }),
  computed: {
    ...mapGetters("authorisation", ["client", "isClient"]),
  },
  watch: {
    client: {
      handler() {
        this.resetTable();
      },
      deep: true,
    },
  },
  methods: {
    selectItems(ids: number[]): void {
      if (!this.client?.id) throw "Selecting id's when clientId is not set"; //@TODO for now this function is only used when client is set

      this.selected = ids.map((id) => ({ id, clientId: this.client?.id }));
    },
    addressBookIndex(data: PaginatedRequest) {
      if (this.client) {
        return addressBookIndex(data, this.client.id);
      }
    },
    addressBookAddressDestroy(item: AddressBookIndexItem) {
      return addressBookAddressDestroy({
        clientId: item.clientId,
        addressBookAddressId: item.id,
      });
    },
    handleEdit(item: AddressBookIndexItem) {
      this.$router.push({
        name: "addressBookAddress.edit",
        params: {
          addressBookAddressId: item.id.toString(),
          clientId: item.clientId.toString(),
        },
      });
    },
    refreshTable(): void {
      (this.$refs.table as any).getData();
    },
    resetTable(): void {
      (this.$refs.table as any).handleReset();
    },
    deselectItems(ids: number[]) {
      this.selected = this.selected.filter((item) => !ids.includes(item.id));
    },
  },
});
